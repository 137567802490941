export const IconDefinitions = {
  Agent: (
    <g>
      <path
        d="M12.501 4.5H0.500977V6.5H12.501V4.5ZM12.501 0.5H0.500977V2.5H12.501V0.5ZM0.500977 10.5H8.50098V8.5H0.500977V10.5Z"
        fill="#6F747B"
      />
      <path
        d="M9.5 11.417V13.5H11.583L17.7266 7.35641L15.6436 5.27336L9.5 11.417ZM19.3375 5.74552C19.5542 5.52888 19.5542 5.17893 19.3375 4.9623L18.0377 3.66248C17.8211 3.44584 17.4711 3.44584 17.2545 3.66248L16.238 4.679L18.321 6.76205L19.3375 5.74552Z"
        fill="#6F747B"
      />
    </g>
  ),
  EarlyEnrollee: (
    <path d="M22.4,6.4l-5.5-4.6L18.4,0l5.5,4.6L22.4,6.4z M7.1,1.8L5.6,0L0.1,4.6l1.5,1.8L7.1,1.8z M22.7,13.3 C22.7,19.2,17.9,24,12,24C6.1,24,1.3,19.2,1.3,13.3S6.1,2.6,12,2.6C17.9,2.6,22.7,7.4,22.7,13.3z M20.3,13.3c0-4.6-3.7-8.3-8.3-8.3 c-4.5,0-8.2,3.6-8.3,8c0.1-0.2,0.3-0.3,0.6-0.3h9.5l-2.2-2.2c-0.3-0.3-0.3-0.8,0-1.1s0.8-0.3,1.1,0l3.5,3.5c0.1,0.1,0.1,0.2,0.2,0.2 c0.1,0.2,0.1,0.4,0,0.6c0,0.1-0.1,0.2-0.2,0.2l-3.5,3.5c-0.1,0.1-0.3,0.2-0.5,0.2s-0.4-0.1-0.5-0.2c-0.3-0.3-0.3-0.8,0-1.1l2.2-2.2 H4.3c-0.2,0-0.4-0.1-0.6-0.3c0.3,4.3,3.9,7.7,8.3,7.7C16.6,21.6,20.3,17.9,20.3,13.3z" />
  ),
  EarlySignee: (
    <path d="M23.9,4.6L18.4,0l-1.5,1.8l5.5,4.6L23.9,4.6z M7.1,1.8L5.6,0L0.1,4.6l1.5,1.8L7.1,1.8z M12,2.6 C6.1,2.6,1.3,7.4,1.3,13.3S6.1,24,12,24c5.9,0,10.7-4.8,10.7-10.7S17.9,2.6,12,2.6z M12,21.6c-4.6,0-8.3-3.7-8.3-8.3S7.4,4.9,12,4.9 s8.3,3.7,8.3,8.3S16.6,21.6,12,21.6z M10.3,15.1l-2.5-2.5l-1.3,1.3l3.8,3.8l7.1-7.1l-1.3-1.3L10.3,15.1z" />
  ),
  Entered: (
    <g>
      <path
        d="M10.59 12.59L12 14L17 9L12 4L10.59 5.41L13.17 8H0.5V10H13.17L10.59 12.59ZM19.5 0H5.5C4.39 0 3.5 0.9 3.5 2V6H5.5V2H19.5V16H5.5V12H3.5V16C3.5 17.1 4.39 18 5.5 18H19.5C20.6 18 21.5 17.1 21.5 16V2C21.5 0.9 20.6 0 19.5 0Z"
        fill="black"
        fillOpacity="0.87"
      />
    </g>
  ),
  JoinCommunity: (
    <g>
      <g>
        <path
          d="M40.8586 6.69193H36.634V21.0849H9.26568V24.2989C9.26568 25.1373 10.1841 25.836 11.2862 25.836H34.4298L42.8791 32.264V8.22904C42.8791 7.39061 41.9607 6.69193 40.8586 6.69193ZM32.4093 16.1941V1.8011C32.4093 0.962667 31.4909 0.263977 30.2052 0.263977H2.83688C1.73481 0.263977 0.816406 0.962667 0.816406 1.8011V24.1592L9.26568 17.7312H30.2052C31.4909 17.871 32.4093 17.1723 32.4093 16.1941Z"
          fill="white"
        />
      </g>
      <path
        d="M10.553 10.8841C11.466 10.8841 12.2061 10.3211 12.2061 9.62648C12.2061 8.9319 11.466 8.36884 10.553 8.36884C9.64003 8.36884 8.8999 8.9319 8.8999 9.62648C8.8999 10.3211 9.64003 10.8841 10.553 10.8841Z"
        fill="inherit"
      />
      <path
        d="M16.0623 10.8841C16.9753 10.8841 17.7154 10.3211 17.7154 9.62648C17.7154 8.9319 16.9753 8.36884 16.0623 8.36884C15.1493 8.36884 14.4092 8.9319 14.4092 9.62648C14.4092 10.3211 15.1493 10.8841 16.0623 10.8841Z"
        fill="inherit"
      />
      <path
        d="M21.5716 10.8841C22.4846 10.8841 23.2247 10.3211 23.2247 9.62648C23.2247 8.9319 22.4846 8.36884 21.5716 8.36884C20.6586 8.36884 19.9185 8.9319 19.9185 9.62648C19.9185 10.3211 20.6586 10.8841 21.5716 10.8841Z"
        fill="inherit"
      />
    </g>
  ),
  NilU: (
    <g>
      <path
        d="M41.5498 4.43848V15.0009H46.9622V9.85091H91.9647V36.4873C91.9647 48.6011 87.188 53.5703 77.6521 59.2977C75.7566 60.435 73.6686 61.6306 71.394 62.8671L71.0849 63.0362L70.5775 63.322L70.0584 63.6136L69.4751 63.8936L68.8919 63.6136L68.3787 63.322L67.8712 63.0362L67.5563 62.8671C65.2817 61.6073 63.1995 60.435 61.2982 59.2977C57.3497 56.9239 54.2177 54.6843 51.9022 52.0072H45.2242C48.1404 56.8656 52.6313 60.4175 58.4928 63.9344C60.3708 65.0659 62.4122 66.2207 64.9084 67.603L65.0717 67.6963L65.2 67.7604L65.4158 67.8771L65.6783 68.0229L66.0224 68.2154L66.1857 68.3087L66.3315 68.3903L66.4773 68.4603L67.0606 68.7286L69.2593 69.9767L71.8198 68.7461L72.403 68.4603L72.5488 68.3903L72.6946 68.3087L72.9279 68.1804L73.2079 68.0229L73.3653 67.9354L73.7094 67.7429C73.8144 67.6904 73.9136 67.6321 74.0127 67.5738C76.509 66.1915 78.5503 65.0426 80.4283 63.9111C91.154 57.4955 97.3422 50.9166 97.3422 36.464V4.43848H41.5498Z"
        fill="#3B4147"
      />
      <path
        d="M11.4377 10.9772C11.2557 11.5699 10.9427 12.114 10.522 12.5694C10.0795 13.0267 9.53625 13.3742 8.93559 13.5842C8.20024 13.8451 7.42349 13.9696 6.64346 13.9517H5.09789C4.30438 13.9718 3.51384 13.8473 2.76495 13.5842C2.16762 13.3854 1.62767 13.0441 1.19174 12.5899C0.755813 12.1357 0.437008 11.5822 0.262857 10.9772C0.0620577 10.3016 -0.0362426 9.59975 -0.0287613 8.89502V7.04033C-0.0375033 6.33169 0.0608111 5.6258 0.262857 4.94652C0.444097 4.35131 0.754807 3.80358 1.1727 3.34262C1.61602 2.88355 2.16162 2.53582 2.76495 2.32779C3.51534 2.07177 4.30529 1.95129 5.09789 1.97201H6.64346C7.42255 1.95379 8.19868 2.07426 8.93559 2.32779C9.53721 2.53584 10.0809 2.88363 10.522 3.34262C10.9417 3.80325 11.2543 4.35094 11.4377 4.94652C11.6495 5.62388 11.7519 6.33071 11.7409 7.04033V8.8892C11.7512 9.59687 11.6489 10.3017 11.4377 10.9772ZM2.60747 11.6304C2.90578 11.9144 3.26184 12.1307 3.65137 12.2646C4.04089 12.3985 4.45471 12.4467 4.86459 12.4061H6.87675C7.28757 12.4476 7.70252 12.3998 8.09312 12.2659C8.48372 12.132 8.84074 11.9152 9.13971 11.6304C9.63781 10.9593 9.87607 10.1306 9.81044 9.29745V6.59125C9.87607 5.7581 9.63781 4.92937 9.13971 4.2583C8.83908 3.97655 8.48151 3.76255 8.09113 3.63075C7.70075 3.49894 7.28665 3.4524 6.87675 3.49426H4.86459C4.45564 3.45326 4.04265 3.50021 3.65334 3.63199C3.26404 3.76377 2.90743 3.97733 2.60747 4.2583C2.10438 4.92712 1.8637 5.75705 1.93091 6.59125V9.29745C1.86425 10.1316 2.10488 10.9613 2.60747 11.6304Z"
        fill="#3B4147"
      />
      <path
        d="M21.9997 14.1548C21.5643 14.1738 21.1369 14.0327 20.7983 13.7582C20.4817 13.4707 20.2086 13.1387 19.9876 12.7726L15.9982 6.35695C15.9224 6.23447 15.8583 6.13533 15.7999 6.05951C15.7924 6.04632 15.7813 6.03551 15.7679 6.02829C15.7545 6.02108 15.7393 6.01775 15.7241 6.01868C15.6483 6.01868 15.6308 6.04784 15.625 6.05368C15.5857 6.12277 15.5675 6.20183 15.5725 6.28115V13.9799H13.7878V6.36863C13.7758 5.85756 13.9247 5.35566 14.2135 4.93387C14.3868 4.72012 14.6097 4.55202 14.8628 4.44426C15.116 4.33649 15.3917 4.29232 15.6658 4.31563C16.0926 4.30361 16.5086 4.45128 16.8323 4.72974C17.1779 5.05083 17.4729 5.42249 17.7071 5.83204L21.609 12.131C21.6629 12.2202 21.7275 12.3025 21.8014 12.376C21.8423 12.4168 21.8714 12.4343 21.8831 12.4343C21.8995 12.4367 21.9162 12.4348 21.9316 12.4286C21.947 12.4225 21.9604 12.4124 21.9706 12.3993C22.0005 12.3439 22.0146 12.2814 22.0114 12.2185V4.47311H23.7961V12.1835C23.82 12.6953 23.6584 13.1985 23.3412 13.6008C23.174 13.7885 22.9665 13.936 22.7341 14.0319C22.5018 14.1279 22.2507 14.1699 21.9997 14.1548Z"
        fill="#3B4147"
      />
      <path
        d="M34.5622 5.33142e-06H29.2606C28.8411 -0.000761483 28.4257 0.0811969 28.0379 0.241181C27.6502 0.401166 27.2978 0.636045 27.0009 0.932368C26.7041 1.22869 26.4685 1.58064 26.3079 1.96809C26.1472 2.35554 26.0645 2.77086 26.0645 3.19031V13.2803C26.0645 13.6998 26.1472 14.1151 26.3079 14.5025C26.4685 14.89 26.7041 15.2419 27.0009 15.5382C27.2978 15.8346 27.6502 16.0695 28.0379 16.2294C28.4257 16.3894 28.8411 16.4714 29.2606 16.4706H34.5622C35.4083 16.4706 36.2198 16.1345 36.8181 15.5362C37.4164 14.9379 37.7525 14.1264 37.7525 13.2803V3.19031C37.7525 2.34419 37.4164 1.53273 36.8181 0.934433C36.2198 0.336135 35.4083 5.33142e-06 34.5622 5.33142e-06ZM34.014 8.48027C34.4483 8.75613 34.8063 9.13682 35.055 9.58731C35.3037 10.0378 35.4351 10.5436 35.4371 11.0582C35.435 11.2655 35.4155 11.4723 35.3787 11.6764C35.2259 12.3487 34.859 12.9533 34.3331 13.3992C33.8072 13.8452 33.1508 14.1084 32.4626 14.1493H28.3799V11.8164H32.3459C32.5568 11.7974 32.7529 11.7 32.8955 11.5434C33.038 11.3868 33.1166 11.1824 33.1158 10.9707C33.1158 10.0608 31.4477 9.43093 30.7537 8.80104V7.75706C31.4477 7.12716 33.1158 6.45061 33.1158 5.56992C33.1215 5.51369 33.1215 5.45701 33.1158 5.40078C33.0763 5.23038 32.987 5.0756 32.8593 4.95614C32.7315 4.83669 32.5711 4.75799 32.3984 4.73006H28.3974V2.39712H32.4801C33.1683 2.43805 33.8247 2.70126 34.3506 3.1472C34.8765 3.59314 35.2434 4.1977 35.3962 4.87003C35.4333 5.07407 35.4528 5.2809 35.4546 5.48826C35.4526 6.00283 35.3212 6.50863 35.0725 6.95911C34.8238 7.4096 34.4658 7.79031 34.0315 8.06617L33.734 8.28781L34.014 8.48027Z"
        fill="#3B4147"
      />
      <path
        d="M75.1673 42.6172V23.8253H73.3126V15.001H86.0155V23.8253H84.0908V44.7052L77.3194 51.4824H62.0036L55.2264 44.7052V23.8253H53.3018V15.001H66.0105V23.8253H60.0731L64.1558 27.4414V42.6056L75.1673 42.6172Z"
        fill="#FE3B1F"
      />
      <path
        d="M12.8312 47.4698L6.4156 32.1657V42.6639H7.98451V47.4873H0V42.6639H1.56891V23.4171H0V18.5938H6.99883L13.4144 33.8687V23.4229H11.9155V18.5996H19.9059V23.4229H18.3311V42.6697H19.9059V47.4931L12.8312 47.4698Z"
        fill="#FE3B1F"
      />
      <path
        d="M24.9566 23.423H23.3877V18.5996H31.3722V23.423H29.8033V42.6698H31.3722V47.4931H23.3877V42.6698H24.9566V23.423Z"
        fill="#FE3B1F"
      />
      <path
        d="M41.853 23.4228V42.6171H45.4924V41.1124H50.2983V47.458H35.4258V42.6346H36.9072V23.3878H35.4258V18.5645H43.4103V23.3878L41.853 23.4228Z"
        fill="#FE3B1F"
      />
    </g>
  ),
  On3White: (
    <g>
      <path
        d="M21.3675 20.6255C20.9991 21.7988 20.4465 22.7868 19.6483 23.5896C18.8501 24.3924 17.8677 25.0717 16.7011 25.504C15.473 25.9362 14.0608 26.1833 12.403 26.1833H9.51715C7.85932 26.1833 6.4471 25.9362 5.21908 25.504C3.99106 25.0717 3.00865 24.3924 2.27184 23.5896C1.47362 22.7868 0.921015 21.7988 0.552609 20.6255C0.184203 19.4522 0 18.1554 0 16.7351V13.2769C0 11.7948 0.184203 10.498 0.552609 9.32469C0.921015 8.15139 1.47362 7.16334 2.27184 6.2988C3.07005 5.49601 4.05246 4.81672 5.21908 4.38445C6.4471 3.95218 7.85932 3.70517 9.51715 3.70517H12.403C14.0608 3.70517 15.473 3.95218 16.7011 4.38445C17.9291 4.81672 18.9115 5.49601 19.6483 6.2988C20.4465 7.10158 20.9991 8.15139 21.3675 9.32469C21.7359 10.498 21.9201 11.7948 21.9201 13.2769V16.7351C21.9815 18.1554 21.7973 19.4522 21.3675 20.6255ZM4.91208 21.8606C5.77169 22.8486 7.18391 23.3426 9.14874 23.3426H12.8942C14.859 23.3426 16.2713 22.8486 17.1309 21.8606C17.9905 20.8725 18.3589 19.4522 18.3589 17.4761V12.4123C18.3589 10.4362 17.9291 9.01593 17.1309 8.02788C16.2713 7.03983 14.859 6.60756 12.8942 6.60756H9.14874C7.18391 6.60756 5.77169 7.10158 4.91208 8.02788C4.05246 9.01593 3.62266 10.4362 3.62266 12.4123V17.4761C3.62266 19.4522 4.05246 20.9343 4.91208 21.8606Z"
        fill="white"
      />
      <path
        d="M41.1383 26.6155C40.2173 26.6155 39.4805 26.3685 38.8665 25.8745C38.3139 25.3805 37.8227 24.7629 37.3315 24.0219L29.9019 11.9801C29.7791 11.7331 29.6563 11.5478 29.5335 11.4243C29.4721 11.3625 29.4721 11.3625 29.4107 11.3625C29.2879 11.3625 29.2265 11.4243 29.2265 11.4243C29.1651 11.486 29.1651 11.6713 29.1651 11.8566V26.3068H25.7881V11.9801C25.7881 10.9303 26.0337 10.004 26.5863 9.26294C27.1389 8.46015 28.0599 8.08963 29.2879 8.08963C30.1476 8.08963 30.8844 8.33664 31.4984 8.89242C32.051 9.38644 32.6036 10.0657 33.1562 10.992L40.4015 22.8486C40.5243 23.0339 40.6471 23.2191 40.7699 23.2809C40.8313 23.3426 40.8927 23.4044 40.9541 23.4044C41.0769 23.4044 41.0769 23.3426 41.1383 23.3426C41.1997 23.2809 41.1997 23.1574 41.1997 23.0339V8.3984H44.5154V22.9103C44.5154 24.0219 44.2084 24.8864 43.6558 25.5657C43.1032 26.245 42.2435 26.6155 41.1383 26.6155Z"
        fill="white"
      />
      <path
        d="M64.6548 31H54.7078C51.3922 31 48.752 28.2829 48.752 25.01V5.99004C48.752 2.65538 51.4536 0 54.7078 0H64.5934C67.909 0 70.5493 2.71713 70.5493 5.99004V24.9482C70.6107 28.2829 67.909 31 64.6548 31Z"
        fill="white"
      />
      <path
        d="M60.2951 26.6156C60.1109 26.6156 59.9881 26.6156 59.8039 26.6156H53.0498V22.1693H60.4793C61.2161 22.0458 61.7687 21.5518 61.8915 20.9343C61.8915 20.8108 61.9529 20.6873 61.9529 20.6255C61.9529 18.8964 58.8215 17.7231 57.5321 16.5498V14.5737C58.8215 13.4004 61.9529 12.1036 61.9529 10.498C61.9529 10.3745 61.9529 10.251 61.8915 10.1893C61.7687 9.57173 61.2161 9.01595 60.5407 8.9542H53.0498V4.44623H59.7425C59.9267 4.44623 60.0495 4.44623 60.2337 4.44623C60.4179 4.44623 60.5407 4.44623 60.6635 4.44623C63.3038 4.63149 65.5756 6.54583 66.0668 9.0777C66.1282 9.44822 66.1896 9.81874 66.1896 10.251C66.1896 12.2271 65.2072 14.0179 63.5494 15.0677L62.9968 15.5L63.5494 15.9323C65.2072 17.0438 66.1896 18.8347 66.1896 20.749C66.1896 21.1195 66.1282 21.5518 66.0668 21.9223C65.5142 24.4542 63.3038 26.3685 60.6635 26.5538C60.6021 26.6156 60.4179 26.6156 60.2951 26.6156Z"
        fill="#1D252C"
      />
    </g>
  ),
  On3NilWhite: (
    <g>
      <path
        d="M60.6445 19.4266H62.7193V8.31319C62.7193 8.11474 62.7554 7.95237 62.8275 7.82608C62.8997 7.71783 63.044 7.64567 63.2244 7.64567C63.3688 7.64567 63.477 7.71783 63.5672 7.82608C63.6394 7.93433 63.7476 8.0967 63.8739 8.27711L69.7734 17.7488C70.1342 18.3261 70.5131 18.7952 70.91 19.1379C71.3069 19.4988 71.8481 19.6611 72.5157 19.6611C73.2914 19.6611 73.8868 19.4266 74.2837 18.9395C74.6806 18.4704 74.8971 17.8209 74.8971 17.0271V5.89567H72.8224V17.0812C72.8224 17.2797 72.7682 17.424 72.6961 17.5323C72.6059 17.6405 72.4976 17.6766 72.3533 17.6766C72.245 17.6766 72.1368 17.6225 72.0286 17.5142C71.9203 17.406 71.8121 17.2797 71.7038 17.0993L65.9306 7.77196C65.4976 7.08639 65.0827 6.56319 64.6858 6.20237C64.2708 5.84155 63.7476 5.66113 63.1342 5.66113C62.2322 5.66113 61.6007 5.93175 61.2219 6.47299C60.8249 7.01423 60.6445 7.66371 60.6445 8.42144V19.4266Z"
        fill="white"
      />
      <path
        d="M81.3057 19.4266H83.3804V5.89567H81.3057V19.4266Z"
        fill="white"
      />
      <path
        d="M89.8016 19.4266H99.9228V17.6225H91.8764V5.89567H89.8016V19.4266Z"
        fill="white"
      />
      <path
        d="M16.6679 15.9859C16.3695 16.8811 15.9432 17.6484 15.3464 18.2879C14.7496 18.9273 13.9822 19.4388 13.0444 19.7799C12.1066 20.1209 10.9982 20.2914 9.71936 20.2914H7.46004C6.18117 20.2914 5.07283 20.1209 4.09236 19.7799C3.15453 19.4388 2.38721 18.9273 1.79041 18.2879C1.19361 17.6484 0.724689 16.8811 0.426288 15.9859C0.127886 15.0907 0 14.0676 0 12.9593V10.2737C0 9.1653 0.127886 8.14221 0.426288 7.20438C0.724689 6.30918 1.15098 5.54186 1.74778 4.8598C2.34458 4.22036 3.1119 3.75145 4.09236 3.41042C5.0302 3.06939 6.13854 2.89887 7.46004 2.89887H9.71936C10.9982 2.89887 12.1066 3.06939 13.0444 3.41042C13.9822 3.75145 14.7496 4.26299 15.3464 4.90243C15.9432 5.54186 16.4121 6.30918 16.6679 7.24701C16.9663 8.14221 17.0941 9.1653 17.0941 10.3163V13.0019C17.0941 14.0676 16.9663 15.0907 16.6679 15.9859ZM3.79396 16.9237C4.43339 17.6911 5.54174 18.0321 7.07638 18.0321H10.0178C11.5524 18.0321 12.6607 17.6484 13.3002 16.9237C13.9396 16.1564 14.2806 15.0481 14.2806 13.5561V9.63422C14.2806 8.09958 13.9396 6.99124 13.3002 6.26655C12.6607 5.54186 11.5524 5.1582 10.0178 5.1582H7.07638C5.54174 5.1582 4.43339 5.54186 3.79396 6.26655C3.15453 6.99124 2.8135 8.14221 2.8135 9.63422V13.5561C2.8135 15.0481 3.15453 16.1991 3.79396 16.9237Z"
        fill="white"
      />
      <path
        d="M32.0568 20.632C31.3321 20.632 30.7353 20.4189 30.309 20.0352C29.8827 19.6942 29.4991 19.1826 29.1154 18.5858L23.3179 9.29275C23.19 9.12224 23.1048 8.95172 23.0195 8.86646C22.9769 8.82384 22.9769 8.82384 22.8916 8.82384C22.7637 8.82384 22.7637 8.86646 22.7637 8.86646C22.7211 8.95172 22.6785 9.03698 22.6785 9.20749V20.4189H20.0781V9.29275C20.0781 8.48281 20.2913 7.75812 20.7176 7.20394C21.1438 6.60714 21.8685 6.30874 22.8064 6.30874C23.4884 6.30874 24.0852 6.52188 24.5115 6.90554C24.9378 7.2892 25.3641 7.80075 25.7904 8.52543L31.46 17.6906C31.5453 17.8185 31.6305 17.9464 31.7584 18.0316C31.801 18.0743 31.8437 18.1169 31.8863 18.1169C31.9716 18.1169 31.9715 18.0743 32.0142 18.0743C32.0568 18.0317 32.0568 17.9464 32.0568 17.8185V6.52188H34.6572V17.7332C34.6572 18.5858 34.444 19.2679 34.0177 19.7794C33.5488 20.3336 32.9094 20.632 32.0568 20.632Z"
        fill="white"
      />
      <path
        d="M50.3448 24H42.629C40.0712 24 37.9824 21.9112 37.9824 19.3535V4.64654C37.9824 2.08881 40.0286 0 42.629 0H50.3448C52.9025 0 54.9913 2.08881 54.9913 4.64654V19.3535C54.9913 21.9112 52.9025 24 50.3448 24Z"
        fill="white"
      />
      <path
        d="M46.935 20.6326C46.8071 20.6326 46.6792 20.6326 46.5939 20.6326H41.3506V17.1797H47.1481C47.7023 17.0944 48.1286 16.7108 48.2564 16.1992C48.2564 16.114 48.2991 16.0287 48.2991 15.9434C48.2991 14.622 45.8692 13.6841 44.8461 12.7889V11.2543C45.8692 10.3591 48.2991 9.33598 48.2991 8.05712C48.2991 7.97186 48.2991 7.88661 48.2564 7.80135C48.1712 7.2898 47.7449 6.90614 47.2334 6.82089H41.3932V3.41058H46.6366C46.7644 3.41058 46.8923 3.41058 47.0202 3.41058C47.1481 3.41058 47.276 3.41058 47.3612 3.41058C49.4501 3.5811 51.1552 5.03048 51.5815 7.03403C51.6241 7.33243 51.6668 7.63083 51.6668 7.92923C51.6668 9.42124 50.8994 10.828 49.5779 11.6806L49.1517 12.0216L49.5779 12.3626C50.8568 13.2152 51.6668 14.622 51.6668 16.114C51.6668 16.4124 51.6241 16.7108 51.5815 17.0092C51.1552 18.9701 49.4501 20.4621 47.3612 20.6326C47.1907 20.6326 47.0628 20.6326 46.935 20.6326Z"
        fill="#1D252C"
      />
    </g>
  ),
  Search: (
    <g>
      <rect fill="#3B4147" height="50" rx="25" width="50" y="0.658203" />
      <path
        d="M32.3743 27.6281H31.1089L30.6027 27.2485C32.2478 25.3502 33.1336 22.9458 33.1336 20.4148C33.1336 14.3405 28.4513 9.6582 22.6301 9.6582C16.6823 9.6582 12 14.3405 12 20.2883C12 26.1095 16.6823 30.9184 22.6301 30.9184C25.2876 30.9184 27.692 29.906 29.4637 28.3874L29.8434 28.8936V30.1591L37.9425 38.2582L40.3469 35.8538L32.3743 27.6281ZM22.6301 27.6281C18.5805 27.6281 15.2903 24.3378 15.2903 20.2883C15.2903 16.2387 18.5805 12.9485 22.6301 12.9485C26.6796 12.9485 29.9699 16.2387 29.9699 20.2883C29.9699 24.3378 26.6796 27.6281 22.6301 27.6281Z"
        fill="#FF4125"
      />
    </g>
  ),
  Baseball: (
    <g>
      <path
        className="st0"
        d="M100.5,190.5C50.6,190.5,10,149.9,10,100S50.6,9.5,100.5,9.5S191,50.1,191,100S150.4,190.5,100.5,190.5z
		 M100.5,17.3c-45.6,0-82.7,37.1-82.7,82.7s37.1,82.7,82.7,82.7s82.7-37.1,82.7-82.7S146.1,17.3,100.5,17.3z"
      />
      <g>
        <path
          className="st0"
          d="M45.3,45.3c-1.8-4.1-3.1-6.4-3.2-6.5L52.4,33c0,0,1.6,2.7,3.7,7.6L45.3,45.3z"
        />
        <path
          className="st0"
          d="M64.9,145l-11.4-3c1.4-5.3,2.5-10.7,3.3-16.1l11.7,1.7C67.6,133.4,66.4,139.2,64.9,145z M69.9,109.8
			l-11.8-0.3c0-1.2,0-2.4,0-3.6c0-4.3-0.2-8.6-0.6-12.9l11.8-1c0.4,4.6,0.6,9.3,0.6,13.9C69.9,107.2,69.9,108.5,69.9,109.8z
			 M55.2,76.7c-1.1-5.4-2.5-10.8-4.1-16l11.3-3.5c1.8,5.6,3.2,11.4,4.4,17.2L55.2,76.7z"
        />
        <path
          className="st0"
          d="M56.1,169.5l-10.7-4.9c1.1-2.3,2-4.7,3-7l11,4.3C58.3,164.5,57.2,167,56.1,169.5z"
        />
      </g>
      <g>
        <path
          className="st0"
          d="M147.8,170c0,0-1.6-2.7-3.7-7.6l10.8-4.8c1.8,4.1,3.1,6.4,3.2,6.5L147.8,170z"
        />
        <path
          className="st0"
          d="M137.8,145.8c-1.8-5.6-3.2-11.4-4.4-17.2l11.6-2.3c1.1,5.4,2.4,10.7,4.1,16L137.8,145.8z M130.9,111
			c-0.4-4.7-0.6-9.3-0.6-13.9c0-1.3,0-2.6,0-3.9l11.8,0.3c0,1.2,0,2.4,0,3.6c0,4.2,0.2,8.6,0.6,12.9L130.9,111z M143.5,77.1
			l-11.7-1.7c0.8-5.9,2-11.7,3.5-17.5l11.4,3C145.3,66.3,144.2,71.7,143.5,77.1z"
        />
        <path
          className="st0"
          d="M151.9,45.3l-11-4.3c1-2.5,2-5,3.2-7.5l10.7,4.9C153.8,40.7,152.8,43,151.9,45.3z"
        />
      </g>
    </g>
  ),
  Basketball: (
    <g>
      <path
        className="st0"
        d="M96.4,188.9v0.2l8.3,0v-0.2c20.1-0.9,38.5-8.4,53-20.6c0,0,5.1-4.5,5.9-5.3c15.2-15.2,24.8-35.9,25.9-58.9h0.2
	l0-8.3h-0.2c-1.1-24.2-11.8-45.8-28.4-61.1c0-0.1,0.1-0.1,0.1-0.1l-6.3-5.4c0,0-0.1,0.1-0.1,0.2c-14-10.8-31.2-17.4-50-18.3v-0.2
	l-8.3,0v0.2c-18.5,0.9-35.6,7.3-49.5,17.8c0,0-4.4,3.1-4.2,3.3c-1.8,1.5-3.5,3.1-5.2,4.7C22.3,52.2,12.6,72.9,11.6,95.9h-0.2l0,8.3
	h0.2C13.7,150,50.5,186.8,96.4,188.9z M19.9,104.1h34.9c-0.5,31.3-6.2,47.7-9.6,54.7C30.5,145,21,125.7,19.9,104.1z M51.7,164.3
	c3.9-7.3,10.8-25.1,11.3-60.2h33.3v76.5C79.6,179.8,64.3,173.8,51.7,164.3z M104.6,104.1h36.2c0.7,30.9,6.3,49.7,10.1,59
	c-13.2,10.6-29.3,16.6-46.3,17.5V104.1z M157.7,157.2C157.6,157.2,157.6,157.2,157.7,157.2c-3.5-9.2-7.9-26.3-8.5-53h32
	C180.1,124.2,171.9,142.9,157.7,157.2z M181.1,95.9h-32.1c0.2-28.3,4.6-44.9,8.1-53.5C171.1,56.2,180.1,75,181.1,95.9z M150.6,36.6
	c-4.1,9-9.6,27.1-9.8,59.2h-36.1V19.4C121.9,20.3,137.8,26.5,150.6,36.6z M96.4,95.9H63.1C62.6,63.7,56.9,44.3,53,34.7
	c12.6-9.2,27.6-14.5,43.4-15.3V95.9z M43.3,42.8c0.9-0.9,1.9-1.7,2.9-2.6c3.5,9.4,8.1,27.3,8.6,55.6H19.9
	C20.9,75.8,29.1,57.1,43.3,42.8z"
      />
    </g>
  ),
  Football: (
    <g>
      <rect className="st0" height="81.3" width="5.9" x="97.3" y="58.7" />
      <rect className="st0" height="5.9" width="28" x="86.5" y="67.6" />
      <rect className="st0" height="5.9" width="28" x="86.5" y="86.9" />
      <rect className="st0" height="5.9" width="28" x="86.5" y="106.2" />
      <rect className="st0" height="5.9" width="28" x="86.5" y="125.5" />
      <path
        className="st0"
        d="M122.9,179.9c-4.3,4.3-15.1,14.3-22.4,14.3c-7.5,0-19.3-10.4-27.9-20.2c-2.7-3-12.1-15.9-12.5-16.4
		C51.3,144,40.8,123.1,40.8,100c0-23.1,9.4-43.5,17.4-56.5c0.3-0.5,6.9-11.1,12-16.7c2-2.4,4.1-4.7,6.2-6.8
		C81.7,14.7,92,5.8,100.5,5.8c8.4,0,20.8,10.9,29.5,21.1c0.2,0.2,6.8,7.2,12.2,16.7c8.2,13.1,17.9,33.6,17.9,56.4
		c0,23.1-10.5,44.1-19.2,57.5c-2.7,4.3-12.1,16-12.5,16.4C126.5,176.1,124.7,178.1,122.9,179.9z M80.5,24.2c-2,2-4,4.1-5.9,6.5
		C69.8,36,63.3,46.5,63.2,46.6C55.7,58.9,46.7,78.3,46.7,100c0,21.7,9.9,41.5,18.3,54.3c2.6,3.5,9.9,13.5,12,15.8
		c10.4,11.8,19.9,18.2,23.5,18.2c2.7,0,9.5-3.9,18.2-12.6c1.7-1.7,3.5-3.6,5.2-5.6c0,0,9.5-11.8,12-15.8
		c8.4-12.8,18.3-32.7,18.3-54.4c0-21.5-9.3-40.9-17.1-53.4c-5.1-9-11.4-15.7-11.5-15.7c-11.3-13.2-20.9-19.2-25.2-19.2
		C96.9,11.7,89.8,15,80.5,24.2z"
      />
      <rect className="st0" height="30.9" width="5.3" x="97.9" y="159.3" />
      <rect className="st0" height="30.9" width="5.3" x="97.9" y="6.5" />
    </g>
  ),
  Swap: (
    <g>
      <path d="M20 9.6449V29.4525L9.92482 19.6754L20 9.6449Z" fill="#E1E6EE" />
      <path
        d="M0 9.6449V29.4525L10.0752 19.6754L0 9.6449ZM1.85464 14.5082L6.96741 19.6247L1.85464 24.5893V14.5082Z"
        fill="#A1B1C8"
      />
      <path
        d="M13.8346 7.97318H19.8496V1.94476C19.8496 1.94476 19.8496 0.982243 18.6967 0.982243C18.6967 0.982243 17.6942 0.982243 17.6942 1.74213V4.52837C17.6942 4.52837 14.8872 0.982243 9.32331 0.982243C9.32331 0.982243 4.1604 0.576972 0.401006 5.28825C0.401006 5.28825 -0.300748 6.04814 0.35088 6.75736C0.35088 6.75736 0.902258 7.36527 2.00501 6.35209C2.00501 6.35209 4.21053 3.05926 9.22306 3.05926C9.22306 3.05926 12.9323 2.45135 16.391 6.04814H13.7845C13.7845 6.04814 13.2331 6.0988 13.2331 6.90934C13.2331 6.96 13.183 7.97318 13.8346 7.97318Z"
        fill="#062A30"
      />
    </g>
  ),
  Undecided: (
    <g>
      <path
        d="M49.9101 35.5812C49.9101 35.5812 52.9706 0.25 23.3855 0.25C23.3855 0.25 0 1.3541 0 25.5655C0 49.7768 24.5627 50.25 24.5627 50.25H38.2958C38.2958 50.25 44.0244 49.7768 44.0244 44.8872V35.5812C43.946 35.5812 50.1455 35.3446 49.9101 35.5812Z"
        fill="#E1E6EE"
      />
      <path
        d="M13.027 14.4456H35.3138C36.8049 14.4456 38.0605 15.7074 38.0605 17.2058V42.3635L32.4888 36.7642H13.027C11.5359 36.7642 10.2803 35.5023 10.2803 34.0039V17.2847C10.2019 15.7074 11.4575 14.4456 13.027 14.4456Z"
        fill="#A1B1C8"
        fillOpacity="0.87"
      />
      <path
        d="M28.565 21.9377C28.565 23.2784 27.9372 24.3825 26.6031 25.7232C25.5045 26.7484 25.1906 27.3005 25.1121 28.2468H23.2287C23.3072 27.0639 23.6996 26.3541 24.6413 25.25C25.8969 23.8304 26.2892 23.1207 26.2892 22.0954C26.2892 20.9125 25.583 20.1238 24.3274 20.1238C23.0718 20.1238 22.2085 20.9913 22.1301 22.5686H20.0112C20.0897 20.0449 21.8162 18.5465 24.4058 18.5465C27.074 18.4677 28.565 19.8872 28.565 21.9377ZM25.5045 30.6916C25.5045 31.4803 24.9552 32.0323 24.1704 32.0323C23.3857 32.0323 22.8363 31.4803 22.8363 30.6916C22.8363 29.903 23.3857 29.3509 24.1704 29.3509C24.9552 29.3509 25.5045 29.903 25.5045 30.6916Z"
        fill="white"
      />
    </g>
  ),
  Withdrawn: (
    <g>
      <path
        d="M6.91 12.59L5.5 14L0.5 9L5.5 4L6.91 5.41L4.33 8H17V10H4.33L6.91 12.59ZM19.5 0H10.5C9.39 0 8.5 0.9 8.5 2V6H10.5V2H19.5V16H10.5V12H8.5V16C8.5 17.1 9.39 18 10.5 18H19.5C20.6 18 21.5 17.1 21.5 16V2C21.5 0.9 20.6 0 19.5 0Z"
        fill="#E53935"
      />
    </g>
  ),
};

export type IconName = keyof typeof IconDefinitions;
